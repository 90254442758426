var Preferences = /** @class */ (function () {
    function Preferences() {
    }
    return Preferences;
}());
export { Preferences };
var CheckoutApi = /** @class */ (function () {
    function CheckoutApi(http) {
        this.http = http;
    }
    CheckoutApi.prototype.makePayment = function (paymentRequest, xValidateOnly, payerId) {
        var value = "; " + document.cookie;
        var parts = value.split("; PORTAL_SESSION=");
        var token = encodeURIComponent(parts.pop().split(';').shift());
        var delimiter;
        if (window.location.href.includes('?')) {
            delimiter = '&';
        }
        else {
            delimiter = '?';
        }
        var xOrigin = "" + window.location.href + delimiter + "PORTAL_SESSION=" + token;
        return this.http.POST("/v2/makePayment", paymentRequest, { headers: { xValidateOnly: xValidateOnly, payerId: payerId, xOrigin: xOrigin }, params: {},
            responseType: '' });
    };
    CheckoutApi.prototype.getPaymentStatus = function (sessionId, payerId) {
        return this.http.GET("/v2/getPaymentStatus/" + sessionId, { headers: { payerId: payerId }, params: {}, responseType: '' });
    };
    CheckoutApi.prototype.getPreferences = function (sessionId, payerId) {
        return this.http.GET("/v2/getPaymentStatus/" + sessionId, { headers: { payerId: payerId }, params: {}, responseType: '' });
    };
    return CheckoutApi;
}());
export { CheckoutApi };
var PreferenceApi = /** @class */ (function () {
    function PreferenceApi(http) {
        this.http = http;
    }
    PreferenceApi.prototype.getPreferences = function () {
        return this.http.GET("/v1/getPreferences");
    };
    return PreferenceApi;
}());
export { PreferenceApi };
