export var getPaymentRequest = function (ccAmount, details) {
    var _a = details || {}, merchantReference = _a.merchantReference, sessionId = _a.sessionId;
    return {
        ccAmount: ccAmount,
        merchantReference: merchantReference,
        sessionId: sessionId,
        storeCard: false,
        checkoutModelRequest: null,
    };
};
