import { defaultAxios } from "./DefaultHttpClient";
var DefaultHttpService = /** @class */ (function () {
    function DefaultHttpService(client) {
        if (client === void 0) { client = defaultAxios; }
        this.client = client;
    }
    DefaultHttpService.prototype.GET = function (path, config) {
        return this.client.get(path, config).then(function (payload) { return payload.data; }, function (payload) { return Promise.reject(payload.response); });
    };
    DefaultHttpService.prototype.POST = function (path, data, config) {
        return this.client.post(path, data, config).then(function (payload) { return payload.data; }, function (payload) { return Promise.reject(payload.response); });
    };
    DefaultHttpService.prototype.PUT = function (path, data, config) {
        return this.client.put(path, data, config).then(function (payload) { return payload.data; }, function (payload) { return Promise.reject(payload.response); });
    };
    DefaultHttpService.prototype.DELETE = function (path, config) {
        return this.client.delete(path, config).then(function (payload) { return payload.data; }, function (payload) { return Promise.reject(payload.response); });
    };
    return DefaultHttpService;
}());
export { DefaultHttpService };
