export var getErrorMessage = function (response) {
    if (!response) {
        return null;
    }
    switch (response.status) {
        case 400: {
            var message = response.data.message;
            if (response.data.formErrors) {
                message = response.data.formErrors.join(",");
            }
            return message;
        }
        default:
            return null;
    }
};
