import React, { useCallback, useEffect, useRef, useState } from "react";
import CheckoutService from "../services/CheckoutService";
import { getPaymentRequest } from "../utils";
import debounce from "lodash.debounce";
import { getErrorMessage } from "../services/ApiErrorHandler";
var getPaymentMessage = function (_a) {
    var status = _a.status, message = _a.message;
    switch (status) {
        case "success":
            return React.createElement("div", { className: "alert alert-success", role: "alert" },
                React.createElement("strong", null, "Success. "),
                message || "Payment processed");
        case "fail":
            return React.createElement("div", { className: "alert alert-danger", role: "alert" },
                React.createElement("strong", null, "Error. "),
                message || "Payment failed");
        case "cancel":
            return React.createElement("div", { className: "alert alert-warning", role: "alert" }, message || "Payment canceled");
    }
};
var WindcaveForm = function (_a) {
    var iframeRef = useRef(null);
    var _b = useState(undefined), iframeUrl = _b[0], setIframeUrl = _b[1];
    var _c = useState(false), isEwayGateway = _c[0], setIsEwayGateway = _c[1];
    var _d = useState(null), amountError = _d[0], setAmountError = _d[1];
    var _e = useState(null), totalBalance = _e[0], setTotalBalance = _e[1];
    var _f = useState(null), overdue = _f[0], setOverdue = _f[1];
    var _g = useState(null), amount = _g[0], setAmount = _g[1];
    var _h = useState(null), amountValue = _h[0], setAmountValue = _h[1];
    var _j = useState(null), payerId = _j[0], setPayerId = _j[1];
    var _k = useState(null), paymentStatus = _k[0], setPaymentStatus = _k[1];
    var _l = useState(null), paymentFetching = _l[0], setPaymentFetching = _l[1];
    var _m = useState(null), paymentDetails = _m[0], setPaymentDetails = _m[1];
    var onMessage = function (e) {
        var paymentData = e.data.payment;
        if (paymentData && paymentData.status) {
            setIframeUrl(null);
            setPaymentFetching(true);
            if (paymentData.status === "success") {
                CheckoutService.makePayment(getPaymentRequest(amount, { merchantReference: paymentDetails.merchantReference, sessionId: paymentData.sessionId }), false, payerId).then(function (res) {
                    setPaymentStatus({ status: res.status === "FAILED" ? "fail" : "success", message: React.createElement("span", null,
                            "Payment processed. Payment reference:",
                            " ",
                            React.createElement("strong", null, res.reference),
                            ". History can be updated"),
                    });
                    setPaymentFetching(false);
                })
                    .catch(function (res) {
                    setPaymentFetching(false);
                    setPaymentStatus({ status: "fail", message: getErrorMessage(res) || "Payment was not processed" });
                });
            }
            else {
                CheckoutService.getPaymentStatus(paymentData.sessionId, payerId).then(function (res) {
                    setPaymentStatus({ status: paymentData.status, message: res.responseText });
                    setPaymentFetching(false);
                })
                    .catch(function (res) {
                    setPaymentStatus({ status: paymentData.status, message: res.message });
                    setPaymentFetching(false);
                });
            }
        }
    };
    useEffect(function () {
        window.addEventListener("message", onMessage);
        return function () {
            window.removeEventListener("message", onMessage);
        };
    }, [amount, payerId, paymentDetails]);
    useEffect(function () {
        var root = document.getElementById("react-payment-form");
        if (root) {
            var payerId_1 = root.getAttribute("data-payerId");
            var balance = root.getAttribute("data-balance");
            var overdue_1 = root.getAttribute("data-overdue");
            if (payerId_1 && balance && overdue_1) {
                setPayerId(payerId_1);
                var balanceParsed = parseFloat(balance);
                var overdueParsed = parseFloat(overdue_1);
                setOverdue(overdueParsed);
                setTotalBalance(balanceParsed);
                setAmount(overdueParsed);
                setAmountValue(overdueParsed);
            }
        }
        return function () {
            setIframeUrl(null);
            setAmountError(null);
            setTotalBalance(null);
            setAmount(null);
            setAmountValue(null);
            setPaymentStatus(null);
            setPaymentDetails(null);
        };
    }, []);
    var validateAmount = function (amount) {
        if (amount > totalBalance || amount <= 0) {
            if (!amountError) {
                setAmountError(true);
            }
            return false;
        }
        if (amountError) {
            setAmountError(false);
        }
        return true;
    };
    useEffect(function () {
        if (payerId && amount && validateAmount(amount)) {
            setIframeUrl(null);
            setPaymentStatus(null);
            setPaymentFetching(true);
            CheckoutService.makePayment(getPaymentRequest(amount), true, payerId)
                .then(function (res) {
                setIframeUrl(res.paymentFormUrl);
                setPaymentFetching(false);
                setPaymentDetails(res);
            })
                .catch(function (res) {
                setPaymentFetching(false);
                setPaymentStatus({ status: "fail", message: getErrorMessage(res) || "Failed to connect payment gateway" });
            });
        }
    }, [amount]);
    useEffect(function () {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.location.replace(iframeUrl);
        }
    }, [iframeUrl]);
    var debounceAmountChange = useCallback(debounce(function (amount) {
        setAmount(amount);
    }, 600), []);
    var onAmountChange = function (e) {
        validateAmount(e.target.value);
        setAmountValue(e.target.value);
        debounceAmountChange(e.target.value);
    };
    return Boolean(totalBalance && totalBalance > 0) ? (React.createElement("div", { className: amountError ? "has-error" : "valid" },
        React.createElement("div", { className: "info-label" },
            "Payment due: $",
            overdue,
            " "),
        !paymentStatus && !paymentFetching && React.createElement("div", null,
            React.createElement("div", { className: "amount-container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-xs-5 amount-label" },
                        React.createElement("label", null,
                            "Amount:",
                            React.createElement("span", null, "*"))),
                    React.createElement("div", null,
                        React.createElement("input", { required: true, min: 1, max: totalBalance, onChange: onAmountChange, value: amountValue, type: "number", name: "amount", placeholder: "Amount", className: "amount-input", pattern: "^\\d*(\\.\\d{2}$)?" }))))),
        paymentStatus && getPaymentMessage(paymentStatus),
        iframeUrl && React.createElement("iframe", { ref: iframeRef, title: "windcave-frame" }),
        paymentFetching && React.createElement("div", { id: "payment-progress-bar" },
            React.createElement("div", { className: "progress progress-striped active " },
                React.createElement("div", { className: "progress-bar progress-bar-warning progress-bar-striped ", role: "progressbar", "aria-valuenow": 100, "aria-valuemin": 0, "aria-valuemax": 100, style: { width: "100%" } }))))) : null;
};
export default WindcaveForm;
