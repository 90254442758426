import { CheckoutApi, PreferenceApi } from "../model/api";
import { DefaultHttpService } from "./HttpService";
var CheckoutService = /** @class */ (function () {
    function CheckoutService() {
        this.checkoutApi = new CheckoutApi(new DefaultHttpService());
        this.preferenceApi = new PreferenceApi(new DefaultHttpService());
    }
    CheckoutService.prototype.makePayment = function (paymentRequest, xValidateOnly, payerId) {
        return this.checkoutApi.makePayment(paymentRequest, xValidateOnly, payerId);
    };
    CheckoutService.prototype.getPaymentStatus = function (sessionId, payerId) {
        return this.checkoutApi.getPaymentStatus(sessionId, payerId);
    };
    CheckoutService.prototype.getPreferences = function () {
        return this.preferenceApi.getPreferences();
    };
    return CheckoutService;
}());
export default new CheckoutService();
