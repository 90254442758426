/*
 * Copyright ish group pty ltd. All rights reserved. https://www.ish.com.au
 * No copying or use of this code is allowed without permission in writing from ish.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from "react";
import CheckoutService from "../services/CheckoutService";
import { getPaymentRequest } from "../utils";
import debounce from "lodash.debounce";
import { getErrorMessage } from "../services/ApiErrorHandler";
var getPaymentMessage = function (_a) {
    var status = _a.status, message = _a.message;
    switch (status) {
        case "success":
            return React.createElement("div", { className: "alert alert-success", role: "alert" },
                React.createElement("strong", null, "Success. "),
                message || "Payment processed");
        case "fail":
            return React.createElement("div", { className: "alert alert-danger", role: "alert" },
                React.createElement("strong", null, "Error. "),
                message || "Payment failed");
        case "cancel":
            return React.createElement("div", { className: "alert alert-warning", role: "alert" }, message || "Payment canceled");
    }
};
var WindcaveForm = function (_a) {
    var _b = useState(undefined), iframeUrl = _b[0], setIframeUrl = _b[1];
    var _c = useState(null), amountError = _c[0], setAmountError = _c[1];
    var _d = useState(null), totalBalance = _d[0], setTotalBalance = _d[1];
    var _e = useState(null), overdue = _e[0], setOverdue = _e[1];
    var _f = useState(null), amount = _f[0], setAmount = _f[1];
    var _g = useState(null), amountValue = _g[0], setAmountValue = _g[1];
    var _h = useState(null), payerId = _h[0], setPayerId = _h[1];
    var _j = useState(null), paymentStatus = _j[0], setPaymentStatus = _j[1];
    var _k = useState(null), paymentFetching = _k[0], setPaymentFetching = _k[1];
    var _l = useState(null), paymentDetails = _l[0], setPaymentDetails = _l[1];
    useEffect(function () {
        var root = document.getElementById("react-payment-form");
        if (root) {
            var payerId_1 = root.getAttribute("data-payerId");
            var balance = root.getAttribute("data-balance");
            var overdue_1 = root.getAttribute("data-overdue");
            if (payerId_1 && balance && overdue_1) {
                setPayerId(payerId_1);
                var balanceParsed = parseFloat(balance);
                var overdueParsed = parseFloat(overdue_1);
                setOverdue(overdueParsed);
                setTotalBalance(balanceParsed);
                setAmount(overdueParsed);
                setAmountValue(overdueParsed);
            }
        }
        return function () {
            setIframeUrl(null);
            setAmountError(null);
            setTotalBalance(null);
            setAmount(null);
            setAmountValue(null);
            setPaymentStatus(null);
            setPaymentDetails(null);
        };
    }, []);
    var validateAmount = function (amount) {
        if (amount > totalBalance || amount <= 0) {
            if (!amountError) {
                setAmountError(true);
            }
            return false;
        }
        if (amountError) {
            setAmountError(false);
        }
        return true;
    };
    useEffect(function () {
        if (payerId && amount && validateAmount(amount)) {
            setIframeUrl(null);
            setPaymentStatus(null);
            setPaymentFetching(true);
            CheckoutService.makePayment(getPaymentRequest(amount), true, payerId)
                .then(function (res) {
                setIframeUrl(res.paymentFormUrl);
                setPaymentFetching(false);
                setPaymentDetails(res);
            })
                .catch(function (res) {
                setPaymentFetching(false);
                setPaymentStatus({ status: "fail", message: getErrorMessage(res) || "Failed to connect payment gateway" });
            });
        }
    }, [amount]);
    var processPayment = function (sessionId) { return __awaiter(void 0, void 0, void 0, function () {
        var paymentStatus_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, CheckoutService.makePayment(getPaymentRequest(amount, { merchantReference: paymentDetails.merchantReference, sessionId: sessionId }), false, payerId)];
                case 1:
                    paymentStatus_1 = _a.sent();
                    setPaymentFetching(false);
                    setPaymentStatus({ status: paymentStatus_1.status === "FAILED" ? "fail" : "success", message: React.createElement("span", null,
                            "Payment processed. Payment reference:",
                            " ",
                            React.createElement("strong", null, paymentStatus_1.reference),
                            ". History can be updated"),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    setPaymentStatus({ status: "fail", message: getErrorMessage(e_1) || "Payment was not processed" });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var pymentCallback = function (result) {
        setPaymentFetching(false);
        var urlParams = new URL(iframeUrl).searchParams;
        var sessionId = urlParams.get("AccessCode");
        if (result === "Cancel")
            return;
        if (result === "Error") {
            setPaymentFetching(false);
            setPaymentStatus({ status: "fail", message: "Payment was not processed" });
        }
        processPayment(sessionId);
        setIframeUrl(null);
    };
    var openPaymentFrame = function () {
        setPaymentFetching(true);
        window.eCrypt.showModalPayment({
            sharedPaymentUrl: iframeUrl,
        }, pymentCallback);
    };
    var debounceAmountChange = useCallback(debounce(function (amount) {
        setAmount(amount);
    }, 600), []);
    var onAmountChange = function (e) {
        validateAmount(e.target.value);
        setAmountValue(e.target.value);
        debounceAmountChange(e.target.value);
    };
    return Boolean(totalBalance && totalBalance > 0) ? (React.createElement("div", { className: amountError ? "has-error" : "valid" },
        React.createElement("div", { className: "info-label" },
            "Payment due: $",
            overdue,
            " "),
        !paymentStatus && !paymentFetching && React.createElement("div", null,
            React.createElement("div", { className: "amount-container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-xs-5 amount-label" },
                        React.createElement("label", null,
                            "Amount:",
                            React.createElement("span", null, "*"))),
                    React.createElement("div", null,
                        React.createElement("input", { required: true, min: 1, max: totalBalance, onChange: onAmountChange, value: amountValue, type: "number", name: "amount", placeholder: "Amount", className: "amount-input", pattern: "^\\d*(\\.\\d{2}$)?" }))))),
        iframeUrl && !paymentFetching && React.createElement("div", { className: "text-center" },
            React.createElement("button", { className: "btn btn-primary", type: "button", onClick: openPaymentFrame }, "Enter card details")),
        paymentStatus && getPaymentMessage(paymentStatus),
        paymentFetching && React.createElement("div", { id: "payment-progress-bar" },
            React.createElement("div", { className: "progress progress-striped active " },
                React.createElement("div", { className: "progress-bar progress-bar-warning progress-bar-striped ", role: "progressbar", "aria-valuenow": 100, "aria-valuemin": 0, "aria-valuemax": 100, style: { width: "100%" } }))))) : null;
};
export default WindcaveForm;
