/*
 * Copyright ish group pty ltd. All rights reserved. https://www.ish.com.au
 * No copying or use of this code is allowed without permission in writing from ish.
 */
import React, { useEffect, useState } from 'react';
import CheckoutService from '../services/CheckoutService';
import EwayForm from './EwayForm';
import WindcaveForm from './WindcaveForm';
var Resolver = function () {
    var _a = useState(false), isEwayGateway = _a[0], setIsEwayGateway = _a[1];
    useEffect(function () {
        CheckoutService.getPreferences().then(function (pref) {
            setIsEwayGateway(["EWAY", "EWAY_TEST"].includes(pref.paymentGatewayType));
        });
    }, []);
    return isEwayGateway ? React.createElement(EwayForm, null) : React.createElement(WindcaveForm, null);
};
export default Resolver;
