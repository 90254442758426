import React from "react";
import ReactDOM from "react-dom";
import Resolver from './payment-form/Resolver';
var query = window.location.href;
var sessionIdMatch = query.match(/sessionId=([^&#?]+)/);
var statusMatch = query.match(/paymentStatus=([^&#?]+)/);
var isRedirect = false;
if (sessionIdMatch && sessionIdMatch[1] && statusMatch && statusMatch[1]) {
    isRedirect = true;
    window.parent.postMessage({
        payment: {
            sessionId: sessionIdMatch[1],
            status: statusMatch[1],
        },
    }, "*");
}
var start = function (target) {
    ReactDOM.render(React.createElement(Resolver, null), target);
};
var config = { attributes: true, childList: true, subtree: true };
var callback = function (mutationsList, observer) {
    for (var _i = 0, mutationsList_1 = mutationsList; _i < mutationsList_1.length; _i++) {
        var mutation = mutationsList_1[_i];
        if (mutation.type === 'childList') {
            var target = document.getElementById("react-payment-form");
            if (target) {
                start(target);
                observer.disconnect();
            }
        }
    }
};
var observer = new MutationObserver(callback);
if (!isRedirect) {
    observer.observe(document, config);
}
